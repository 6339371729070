.discounts__table{
    min-width: 450px;
}
.discount__action{
    cursor: pointer;
    background-color: var(--primary);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
thead th{
    text-align: center;
    font-weight: 400;
    color: var(--primary);
}
td{
    vertical-align: middle !important;
}
.admin-clients__table{
    min-width: 450px;
}
html body .pricing .rounded-box .search__input{
    box-shadow: none;
    border: 1px solid var(--grey);
}
.pricing__image{
    width: 20rem;
    height: 10rem;
    border: 1px solid var(--grey);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.image__delete{
    border-radius: 50%;
    border: 1px solid var(--grey);
    width: 2.5rem;
    height: 2.5rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5px;
    top: 5px;
}
.image__delete img{
    width: 0.8rem;
}