/*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */

 .datepicker {
    padding: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    direction: ltr;
  }
  .datepicker-inline {
    width: 220px;
  }
  .datepicker-rtl {
    direction: rtl;
  }
  .datepicker-rtl.dropdown-menu {
    left: auto;
  }
  .datepicker-rtl table tr td span {
    float: right;
  }
  .datepicker-dropdown {
    top: 0;
    left: 0;
  }
  .datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #999;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
  }
  .datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-top: 0;
    position: absolute;
  }
  .datepicker-dropdown.datepicker-orient-left:before {
    left: 6px;
  }
  .datepicker-dropdown.datepicker-orient-left:after {
    left: 7px;
  }
  .datepicker-dropdown.datepicker-orient-right:before {
    right: 6px;
  }
  .datepicker-dropdown.datepicker-orient-right:after {
    right: 7px;
  }
  .datepicker-dropdown.datepicker-orient-bottom:before {
    top: -7px;
  }
  .datepicker-dropdown.datepicker-orient-bottom:after {
    top: -6px;
  }
  .datepicker-dropdown.datepicker-orient-top:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid #999;
  }
  .datepicker-dropdown.datepicker-orient-top:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #fff;
  }
  .datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .datepicker td,
  .datepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: none;
  }
  .table-striped .datepicker table tr td,
  .table-striped .datepicker table tr th {
    background-color: transparent;
  }
  .datepicker table tr td.day:hover,
  .datepicker table tr td.day.focused {
    background: #eee;
    cursor: pointer;
  }
  .datepicker table tr td.old,
  .datepicker table tr td.new {
    color: #999;
  }
  .datepicker table tr td.disabled,
  .datepicker table tr td.disabled:hover {
    background: none;
    color: #999;
    cursor: default;
  }
  .datepicker table tr td.highlighted {
    background: #d9edf7;
    border-radius: 0;
  }
  .datepicker table tr td.today,
  .datepicker table tr td.today:hover,
  .datepicker table tr td.today.disabled,
  .datepicker table tr td.today.disabled:hover {
    background-color: #fde19a;
    background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
    background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
    border-color: #fdf59a #fdf59a #fbed50;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #000;
  }
  .datepicker table tr td.today:hover,
  .datepicker table tr td.today:hover:hover,
  .datepicker table tr td.today.disabled:hover,
  .datepicker table tr td.today.disabled:hover:hover,
  .datepicker table tr td.today:active,
  .datepicker table tr td.today:hover:active,
  .datepicker table tr td.today.disabled:active,
  .datepicker table tr td.today.disabled:hover:active,
  .datepicker table tr td.today.active,
  .datepicker table tr td.today:hover.active,
  .datepicker table tr td.today.disabled.active,
  .datepicker table tr td.today.disabled:hover.active,
  .datepicker table tr td.today.disabled,
  .datepicker table tr td.today:hover.disabled,
  .datepicker table tr td.today.disabled.disabled,
  .datepicker table tr td.today.disabled:hover.disabled,
  .datepicker table tr td.today[disabled],
  .datepicker table tr td.today:hover[disabled],
  .datepicker table tr td.today.disabled[disabled],
  .datepicker table tr td.today.disabled:hover[disabled] {
    background-color: #fdf59a;
  }
  .datepicker table tr td.today:active,
  .datepicker table tr td.today:hover:active,
  .datepicker table tr td.today.disabled:active,
  .datepicker table tr td.today.disabled:hover:active,
  .datepicker table tr td.today.active,
  .datepicker table tr td.today:hover.active,
  .datepicker table tr td.today.disabled.active,
  .datepicker table tr td.today.disabled:hover.active {
    background-color: #fbf069 \9;
  }
  .datepicker table tr td.today:hover:hover {
    color: #000;
  }
  .datepicker table tr td.today.active:hover {
    color: #fff;
  }
  .datepicker table tr td.range,
  .datepicker table tr td.range:hover,
  .datepicker table tr td.range.disabled,
  .datepicker table tr td.range.disabled:hover {
    background: #eee;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .datepicker table tr td.range.today,
  .datepicker table tr td.range.today:hover,
  .datepicker table tr td.range.today.disabled,
  .datepicker table tr td.range.today.disabled:hover {
    background-color: #f3d17a;
    background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
    background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
    border-color: #f3e97a #f3e97a #edde34;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .datepicker table tr td.range.today:hover,
  .datepicker table tr td.range.today:hover:hover,
  .datepicker table tr td.range.today.disabled:hover,
  .datepicker table tr td.range.today.disabled:hover:hover,
  .datepicker table tr td.range.today:active,
  .datepicker table tr td.range.today:hover:active,
  .datepicker table tr td.range.today.disabled:active,
  .datepicker table tr td.range.today.disabled:hover:active,
  .datepicker table tr td.range.today.active,
  .datepicker table tr td.range.today:hover.active,
  .datepicker table tr td.range.today.disabled.active,
  .datepicker table tr td.range.today.disabled:hover.active,
  .datepicker table tr td.range.today.disabled,
  .datepicker table tr td.range.today:hover.disabled,
  .datepicker table tr td.range.today.disabled.disabled,
  .datepicker table tr td.range.today.disabled:hover.disabled,
  .datepicker table tr td.range.today[disabled],
  .datepicker table tr td.range.today:hover[disabled],
  .datepicker table tr td.range.today.disabled[disabled],
  .datepicker table tr td.range.today.disabled:hover[disabled] {
    background-color: #f3e97a;
  }
  .datepicker table tr td.range.today:active,
  .datepicker table tr td.range.today:hover:active,
  .datepicker table tr td.range.today.disabled:active,
  .datepicker table tr td.range.today.disabled:hover:active,
  .datepicker table tr td.range.today.active,
  .datepicker table tr td.range.today:hover.active,
  .datepicker table tr td.range.today.disabled.active,
  .datepicker table tr td.range.today.disabled:hover.active {
    background-color: #efe24b \9;
  }
  .datepicker table tr td.selected,
  .datepicker table tr td.selected:hover,
  .datepicker table tr td.selected.disabled,
  .datepicker table tr td.selected.disabled:hover {
    background-color: #9e9e9e;
    background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
    background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
    background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
    background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
    background-image: linear-gradient(to bottom, #b3b3b3, #808080);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
    border-color: #808080 #808080 #595959;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .datepicker table tr td.selected:hover,
  .datepicker table tr td.selected:hover:hover,
  .datepicker table tr td.selected.disabled:hover,
  .datepicker table tr td.selected.disabled:hover:hover,
  .datepicker table tr td.selected:active,
  .datepicker table tr td.selected:hover:active,
  .datepicker table tr td.selected.disabled:active,
  .datepicker table tr td.selected.disabled:hover:active,
  .datepicker table tr td.selected.active,
  .datepicker table tr td.selected:hover.active,
  .datepicker table tr td.selected.disabled.active,
  .datepicker table tr td.selected.disabled:hover.active,
  .datepicker table tr td.selected.disabled,
  .datepicker table tr td.selected:hover.disabled,
  .datepicker table tr td.selected.disabled.disabled,
  .datepicker table tr td.selected.disabled:hover.disabled,
  .datepicker table tr td.selected[disabled],
  .datepicker table tr td.selected:hover[disabled],
  .datepicker table tr td.selected.disabled[disabled],
  .datepicker table tr td.selected.disabled:hover[disabled] {
    background-color: #808080;
  }
  .datepicker table tr td.selected:active,
  .datepicker table tr td.selected:hover:active,
  .datepicker table tr td.selected.disabled:active,
  .datepicker table tr td.selected.disabled:hover:active,
  .datepicker table tr td.selected.active,
  .datepicker table tr td.selected:hover.active,
  .datepicker table tr td.selected.disabled.active,
  .datepicker table tr td.selected.disabled:hover.active {
    background-color: #666666 \9;
  }
  .datepicker table tr td.active,
  .datepicker table tr td.active:hover,
  .datepicker table tr td.active.disabled,
  .datepicker table tr td.active.disabled:hover {
    background-color: #006dcc;
    background-image: -moz-linear-gradient(to bottom, #08c, #0044cc);
    background-image: -ms-linear-gradient(to bottom, #08c, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#0044cc));
    background-image: -webkit-linear-gradient(to bottom, #08c, #0044cc);
    background-image: -o-linear-gradient(to bottom, #08c, #0044cc);
    background-image: linear-gradient(to bottom, #08c, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .datepicker table tr td.active:hover,
  .datepicker table tr td.active:hover:hover,
  .datepicker table tr td.active.disabled:hover,
  .datepicker table tr td.active.disabled:hover:hover,
  .datepicker table tr td.active:active,
  .datepicker table tr td.active:hover:active,
  .datepicker table tr td.active.disabled:active,
  .datepicker table tr td.active.disabled:hover:active,
  .datepicker table tr td.active.active,
  .datepicker table tr td.active:hover.active,
  .datepicker table tr td.active.disabled.active,
  .datepicker table tr td.active.disabled:hover.active,
  .datepicker table tr td.active.disabled,
  .datepicker table tr td.active:hover.disabled,
  .datepicker table tr td.active.disabled.disabled,
  .datepicker table tr td.active.disabled:hover.disabled,
  .datepicker table tr td.active[disabled],
  .datepicker table tr td.active:hover[disabled],
  .datepicker table tr td.active.disabled[disabled],
  .datepicker table tr td.active.disabled:hover[disabled] {
    background-color: #0044cc;
  }
  .datepicker table tr td.active:active,
  .datepicker table tr td.active:hover:active,
  .datepicker table tr td.active.disabled:active,
  .datepicker table tr td.active.disabled:hover:active,
  .datepicker table tr td.active.active,
  .datepicker table tr td.active:hover.active,
  .datepicker table tr td.active.disabled.active,
  .datepicker table tr td.active.disabled:hover.active {
    background-color: #003399 \9;
  }
  .datepicker table tr td span {
    display: block;
    width: 23%;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  .datepicker table tr td span:hover,
  .datepicker table tr td span.focused {
    background: #eee;
  }
  .datepicker table tr td span.disabled,
  .datepicker table tr td span.disabled:hover {
    background: none;
    color: #999;
    cursor: default;
  }
  .datepicker table tr td span.active,
  .datepicker table tr td span.active:hover,
  .datepicker table tr td span.active.disabled,
  .datepicker table tr td span.active.disabled:hover {
    background-color: #006dcc;
    background-image: -moz-linear-gradient(to bottom, #08c, #0044cc);
    background-image: -ms-linear-gradient(to bottom, #08c, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#0044cc));
    background-image: -webkit-linear-gradient(to bottom, #08c, #0044cc);
    background-image: -o-linear-gradient(to bottom, #08c, #0044cc);
    background-image: linear-gradient(to bottom, #08c, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .datepicker table tr td span.active:hover,
  .datepicker table tr td span.active:hover:hover,
  .datepicker table tr td span.active.disabled:hover,
  .datepicker table tr td span.active.disabled:hover:hover,
  .datepicker table tr td span.active:active,
  .datepicker table tr td span.active:hover:active,
  .datepicker table tr td span.active.disabled:active,
  .datepicker table tr td span.active.disabled:hover:active,
  .datepicker table tr td span.active.active,
  .datepicker table tr td span.active:hover.active,
  .datepicker table tr td span.active.disabled.active,
  .datepicker table tr td span.active.disabled:hover.active,
  .datepicker table tr td span.active.disabled,
  .datepicker table tr td span.active:hover.disabled,
  .datepicker table tr td span.active.disabled.disabled,
  .datepicker table tr td span.active.disabled:hover.disabled,
  .datepicker table tr td span.active[disabled],
  .datepicker table tr td span.active:hover[disabled],
  .datepicker table tr td span.active.disabled[disabled],
  .datepicker table tr td span.active.disabled:hover[disabled] {
    background-color: #0044cc;
  }
  .datepicker table tr td span.active:active,
  .datepicker table tr td span.active:hover:active,
  .datepicker table tr td span.active.disabled:active,
  .datepicker table tr td span.active.disabled:hover:active,
  .datepicker table tr td span.active.active,
  .datepicker table tr td span.active:hover.active,
  .datepicker table tr td span.active.disabled.active,
  .datepicker table tr td span.active.disabled:hover.active {
    background-color: #003399 \9;
  }
  .datepicker table tr td span.old,
  .datepicker table tr td span.new {
    color: #999;
  }
  .datepicker .datepicker-switch {
    width: 145px;
  }
  .datepicker .datepicker-switch,
  .datepicker .prev,
  .datepicker .next,
  .datepicker tfoot tr th {
    cursor: pointer;
  }
  .datepicker .datepicker-switch:hover,
  .datepicker .prev:hover,
  .datepicker .next:hover,
  .datepicker tfoot tr th:hover {
    background: #eee;
  }
  .datepicker .prev.disabled,
  .datepicker .next.disabled {
    visibility: hidden;
  }
  .datepicker .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }
  .input-append.date .add-on,
  .input-prepend.date .add-on {
    cursor: pointer;
  }
  .input-append.date .add-on i,
  .input-prepend.date .add-on i {
    margin-top: 3px;
  }
  .input-daterange input {
    text-align: center;
  }
  .input-daterange input:first-child {
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
  }
  .input-daterange input:last-child {
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
  }
  .input-daterange .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: 20px;
    padding: 4px 5px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
    vertical-align: middle;
    background-color: #eee;
    border: 1px solid #ccc;
    margin-left: -5px;
    margin-right: -5px;
  }
  .datepicker.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: 160px;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    color: #333333;
    font-size: 13px;
    line-height: 20px;
  }
  .datepicker.dropdown-menu th,
  .datepicker.datepicker-inline th,
  .datepicker.dropdown-menu td,
  .datepicker.datepicker-inline td {
    padding: 4px 5px;
  }
  /*# sourceMappingURL=bootstrap-datepicker.standalone.css.map */