.nav__item{
    margin-bottom: 1.5rem;
    width: 100%;
}
.nav__link{
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--textLight);
    display: inline-block;
    transition: var(--transition);
}
.nav__link:hover{
    color: var(--text);
}
.nav__item.active .nav__link{
    color: var(--text);
}
html body .nav__item--dropdown::after{
    display: none;
}
.nav__item.active .nav__link::after{
    content: '';
    width: 100%;
    height: 3px;
    background-color: var(--text);
    display: block;
}
.nav--mobile{
    position: fixed;
    z-index: 999999;
    background: var(--light);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 5rem;
    overflow-y: scroll;
}
.nav__close{
    position: absolute;
    right: 3rem;
    top: 3rem;
    cursor: pointer;
}
.nav__trigger{
    position: fixed;
    bottom: 3rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
}
.dropdown__chevron{
    color: var(--textLight);
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    border-top: .4em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.nav__item--dropdown .nav__link{
    display: flex;
    align-items: center;
}