.footer__top{
    background-color: white;
}
.footer .h3{
    color: #5a5a5a;
}
.footer__mail a{
    color: #5a5a5a;
}
@media (min-width: 768px){
    .footer{
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}