.client__label{
    font-size: 1.2rem;
    text-transform: uppercase;
}
.client__value{
    font-size: 1.6rem;
    font-weight: 700;
}
.invoice__status {
    color: var(--primary);
    display: flex;
    align-items: center;
}
.order__status {
    color: var(--primary);
    display: flex;
    align-items: center;
}