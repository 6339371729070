.add-to-cart{
    width: 24rem;
    height: 4rem;
    background-color: var(--primary);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.2);
    cursor: pointer;
    transition: var(--transition);
    position: absolute;
    top: 3rem;
    right: -1rem;
    z-index: 2;
    color: white;
    padding: 16px;
}
.go-to-cart{
    width: 24rem;
    height: 4rem;
    background-color: var(--primary);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.2);
    cursor: pointer;
    transition: var(--transition);
    position: absolute;
    bottom: 6rem;
    right: 1.5rem;
    z-index: 2;
    color: white;
    padding: 8px;
}

.add-to-cart p {
    margin-right: 16px;
}
@media (min-width: 768px){
    .add-to-cart{
        right: -2.5rem;
    }
}
.add-to-cart:hover{
    background-color: var(--primaryDark);
    -webkit-box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.3);
}