.news__discount {
    color: var(--primary);
    font-weight: 700;
}
.news__text{
    line-height: 2.8rem;
}
.news__more{
    color: var(--primary);
    text-transform: uppercase;
}
.dashboard__balance{
    font-size: 2rem;
}