.pagination__item, .pagination__prev, .pagination__next{
    font-weight: 600;
    font-size: 1.4rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text);
    cursor: pointer;
}
.pagination__item.active{
    background-color: var(--primary);
    border-radius: 50%;
    color: white;
    font-weight: 700;
}