.cart__quantity{
    font-weight: 700;
}
.cart__items .product__img img{
    height: 6rem;
}
.cart .price__main {
    font-size: 2.8rem;
}
.cart .price__discount {
    font-size: 1.5rem;
}
.cart .quantity__control{
    background-color: var(--primary);
    color: white;
}
.cart .quantity__control:hover{
    background-color: var(--primaryDark);
}
.cart__remove{
    cursor: pointer;
    transition: var(--transition);
}
.cart__remove:hover{
    opacity: 0.5;
}
.cart__total{
    border-top: 1px solid var(--grey);
}
.total__price{
    font-size: 2.2rem;
    font-weight: 700;
    color: var(--primary);
}