.header{
    background-color: var(--primary);
}
.menu__item{
    transition: var(--transition);
}
.menu__item:hover{
    background-color: var(--primaryDark);
}
.menu__link:hover{
    text-decoration: none;
}
.menu__item--active{
    background-color: var(--primaryLight);
}
.menu__title{
    color: white;
}
.minicart{
    margin: 2rem 0;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
    width: auto !important;
}
.minicart__counter{
    font-size: 1.4rem;
    color: white;
    font-weight: 700;
    border-radius: 50%;
    background-color: var(--primary);
    position: absolute;
    width: 2.1rem;
    height: 2.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 3px;
}
.minicart__link, .minicart__remove, .logout{
    transition: var(--transition);
}
.minicart__link:hover, .minicart__remove:hover, .logout:hover{
    opacity: 0.5;
}
.notifications{
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
    background-color: white;
    transition: var(--transition);
}
.notifications:hover{
    background-color: var(--primaryDark);
}
.notifications__counter{
    font-size: 1rem;
    color: white;
    font-weight: 700;
    border-radius: 50%;
    background-color: var(--primary);
    position: absolute;
    width: 1.7rem;
    height: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 9px;
    right: 18px;
}
.lang .dropdown-toggle{
    color: white;
    font-size: 1.2rem;
    text-transform: uppercase;
}

.lang .lang__select{
    color: white;
    font-size: 1.2rem;
    text-transform: uppercase;
    background: none;
    border: 0;
}

/* Header mobile */
.header-mobile {
    z-index: 999999 !important;
}
.header-mobile .header__logo img {
    max-width: 17rem;
}
@media (min-width: 576px){
    .header-mobile .header__logo img {
        max-width: none;
    }
}

.header-mobile__inner {
    width: 250px;
    right: -250px;
    z-index: 99;
    transition: var(--transition);
    overflow: hidden;
    top: 0;
    background-color: var(--primary);
    padding: 1.5rem 0;
    padding-top: 10rem;
    -webkit-box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.08);
}

.header-mobile__hidden {
    overflow: auto;
    padding-bottom: 100px;
    height: 100vh;
}

.header-mobile__inner.show-menu {
    right: 0;
}

html body .header-mobile .header__menu__item a {
    color: var(--textLight);
}

.hamburger {
    background-color: var(--primaryDark);
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    border: 0;
    margin: 0;
    overflow: visible;
    z-index: 999;
    right: 15px;
    top: 20px;
    display: flex;
}
.hamburger.is-active{
    background-color: transparent;
}


.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: white;
}

.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}

.hamburger-inner::before {
    top: -10px;
}

.hamburger-inner::after {
    bottom: -10px;
}

.hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}