.confirmationModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 1000;
    border-radius: 16px;
  }
.confirmationModal button {
    margin:0px 16px 16px 16px;
  }
.confirmationModal p {
    margin:16px;
    text-align: center;
  }
.confirmationModalContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000ba;
    z-index: 9999;
  }