.order__action{
    cursor: pointer;
    background-color: var(--primary);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
thead th{
    text-align: center;
    font-weight: 400;
    color: var(--primary);
}