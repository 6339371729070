.price{
    display: flex;
    align-items: baseline;
}
.price__main{
    font-size: 3.2rem;
    font-weight: 700;
    color: var(--primary);
}
.price__small{
    font-size: 14px;
    font-weight: 700;
    color: var(--primary);
}
.price__discount{
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--textLight);
    position: relative;
    display: flex;
    align-items: center;
}
.price__discount::after{
    content: '';
    width: 100%;
    height: 0.2rem;
    background-color: var(--primary);
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.price__label{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.4rem;
}