.account__label{
    font-size: 1.2rem;
    text-transform: uppercase;
}
.account__value{
    font-size: 1.6rem;
    font-weight: 700;
}
.account__address--edit{
    top: -20px;
    background: var(--light);
    z-index: 1;
    position: relative;
}
.edit__trigger{
    font-size: 1.2rem;
    text-transform: uppercase;
    color: var(--primary);
    font-weight: 400;
}